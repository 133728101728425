import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import device from "../../utils/device";
import SectionTitle from "../SectionTitle";
import TeamMember from "./team/TeamMember";

const Wrapper = styled.section`
  display: grid;
  padding: ${props =>
    `${props.theme.sectionMarginTB}rem ${props.theme.sectionMargin}rem`};

  @media screen and (orientation: landscape), ${device.tablet} {
    padding: ${props =>
      `${props.theme.sectionMarginTBBig}rem ${props.theme.sectionMargin}rem`};
  }
`;

const Intro = styled.div`
  display: none;
  text-align: center;
  padding-bottom: 1.5rem;

  @media screen and (orientation: landscape), ${device.tablet} {
    display: block;
    width: 85%;
    justify-self: center;
  }
`;

const Members = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Team = ({ contentfulHomepage }) => {
  const {
    teamTitle: title,
    teamIntro: intro,
    teamMembers: members
  } = contentfulHomepage;

  const membersContent = members.map((m, index) => (
    <TeamMember
      key={`member-${index}`}
      name={m.name}
      title={m.title}
      photo={m.photo}
    />
  ));

  return (
    <Wrapper id="team">
      <SectionTitle justify="center">{title}</SectionTitle>
      <Intro>{intro.txt}</Intro>
      <Members>{membersContent}</Members>
    </Wrapper>
  );
};

export default Team;

export const teamFragment = graphql`
  fragment Team on ContentfulHomepage {
    teamTitle
    teamIntro {
      txt: teamIntro
    }
    teamMembers {
      name
      title
      photo {
        resolutions(width: 100, height: 100, quality: 90) {
          ...GatsbyContentfulResolutions_withWebp_noBase64
        }
      }
    }
  }
`;
