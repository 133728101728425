import { css } from "styled-components";

const color = {
  black: "#363636",
  white: "#fff",
  orange: "#ff6600",
  grey: "#4a4a4a",
  lightGrey: "#cccccc"
};

const font = {
  title: { size: 2.3, weight: 800 }
};

const setFont = ({ size, weight }) => css`
  font-size: ${size}em;
  font-weight: ${weight};
`;

export default {
  color,
  sectionMargin: 1.6,
  sectionMarginTB: 2,
  sectionMarginTBBig: 4
};
