import React from "react";
import { graphql } from "gatsby";
import styled, { withTheme } from "styled-components";

import device from "../../utils/device";
import ImageSection from "../ImageSection";
import SectionTitle from "../SectionTitle";

const Content = styled.div`
  text-align: right;
  color: ${props => props.theme.color.white};
  line-height: 1.3em;
  width: 85%;
  justify-self: end;

  @media screen and (orientation: landscape), ${device.tablet} {
    justify-self: end;
    width: 70%;
  }

  @media screen and (orientation: landscape), ${device.laptop} {
    width: 60%;
  }

  @media screen and (orientation: landscape), ${device.desktop} {
    width: 50%;
  }
`;

const ContentPara = styled.p`
  padding-bottom: 1rem;
`;

const Vision = ({
  contentfulHomepage,
  visionImagePortrait,
  visionImageLandscape,
  theme
}) => {
  const { visionTitle: title, visionContent: content } = contentfulHomepage;
  const paragraphs = content.txt.split("\n").filter(txt => txt.length > 2);

  const renderPara = (p, index) => <ContentPara key={index}>{p}</ContentPara>;

  return (
    <ImageSection
      id="vision"
      portrait={visionImagePortrait}
      landscape={visionImageLandscape}
    >
      <SectionTitle justify="end" color={theme.color.white}>
        {title}
      </SectionTitle>
      <Content>{paragraphs.map((p, index) => renderPara(p, index))}</Content>
    </ImageSection>
  );
};

export default withTheme(Vision);

export const visionFragment = graphql`
  fragment Vision on ContentfulHomepage {
    visionTitle
    visionContent {
      txt: visionContent
    }
  }
`;
