import React from "react";
import styled from "styled-components";
import createReactClass from "create-react-class";
import Carousel from "nuka-carousel";

import Icomoon from "../../Icomoon";

const NavIcon = styled(Icomoon)`
  color: ${props => props.theme.color.white};
  font-size: 2rem;
  font-weight: 800;
`;

const Button = styled.button`
  display: "grid";
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${props => props.theme.color.white};
  font-size: 2rem;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
  height: 92px;
`;

const ReviewCarousel = createReactClass({
  getInitialState() {
    return { slideIndex: 0 };
  },

  render() {
    return (
      <Carousel
        ref="carousel"
        heightMode="max"
        renderTopLeftControls={({ previousSlide }) => (
          <Button aria-label="Previous" onClick={previousSlide}>
            <NavIcon>arrowleft</NavIcon>
          </Button>
        )}
        renderTopRightControls={({ nextSlide }) => (
          <Button aria-label="Next" onClick={nextSlide}>
            <NavIcon>arrowright</NavIcon>
          </Button>
        )}
        renderBottomCenterControls={() => null}
        renderCenterRightControls={() => null}
        renderCenterLeftControls={() => null}
      >
        {this.props.children}
      </Carousel>
    );
  }
});

export default ReviewCarousel;
