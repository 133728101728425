import React from "react";
import styled from "styled-components";
import Headroom from "react-headroom";
import Scrollchor from "react-scrollchor";

import device from "../../utils/device";
import logo from "../../images/logo.png";

const Wrapper = styled.header`
  position: absolute;
  width: 100%;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.2rem;
  align-items: center;
  background-color: ${props => props.theme.color.white};
  opacity: 1;

  padding: 0 ${props => props.theme.sectionMargin}rem;

  border-bottom: 1px solid ${props => props.theme.color.lightGrey};

  .headroom--unfixed & {
    background: rgba(255, 255, 255, 0.6);
    border-bottom: 0;
  }
`;

const Logo = styled.img`
  /* height: 48px; */
  height: 30px;
  vertical-align: middle;
  margin: 0.2rem 0;

  @media screen and ${device.tablet} {
    height: 46px;
  }

  @media screen and ${device.laptopL} {
    height: 54px;
  }

  @media screen and ${device.desktop} {
    height: 88px;
  }
`;

const LogoLink = styled(Scrollchor).attrs({
  disableHistory: true
})`
  text-decoration: none;
`;

const SectionLink = styled(Scrollchor).attrs({
  disableHistory: true
})`
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.theme.color.grey};

  padding: 0.3rem 0.2rem;
  margin: 0.2rem 0;

  &:hover {
    color: ${props => props.theme.color.orange};
    border-color: ${props => props.theme.color.orange};
  }

  @media screen and ${device.tablet} {
    font-size: 0.7rem;
  }

  @media screen and ${device.laptopL} {
    font-size: 0.7rem;
    padding: 0.3rem 0.4rem;
  }

  @media screen and ${device.desktop} {
    padding: 0.3rem 0.5rem;
  }
`;

const CtaLink = styled(SectionLink)`
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.orange};
  border-radius: 3px;
  border: 1px solid ${props => props.theme.color.orange};

  &:after {
    display: none;
  }

  &:hover {
    color: ${props => props.theme.color.orange};
    background-color: ${props => props.theme.color.white};
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      width: 0
    };

    this.updateViewportDimension = this.updateViewportDimension.bind(this);
  }

  orientation() {
    if (this.state.width > this.state.height) return "landscape";
    return "portrait";
  }

  componentWillMount() {
    this.updateViewportDimension();
    typeof window !== "undefined" &&
      window.addEventListener("resize", this.updateViewportDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewportDimension);
  }

  updateViewportDimension() {
    this.setState({
      height: typeof window !== "undefined" ? window.innerHeight : 0,
      width: typeof window !== "undefined" ? window.innerWidth : 0
    });
  }

  render() {
    return (
      <Headroom style={{ zIndex: 10 }} pinStart={this.state.height}>
        <Wrapper>
          <LogoLink to="">
            <Logo alt="Menu logo" src={logo} />
          </LogoLink>
          <SectionLink to="intro">About</SectionLink>
          <SectionLink to="vision">Vision</SectionLink>
          <SectionLink to="features">Features</SectionLink>
          <SectionLink to="reviews">Testimonials</SectionLink>
          <SectionLink to="screenshots">Screenshots</SectionLink>
          <SectionLink to="price">Price</SectionLink>
          <SectionLink to="team">Team</SectionLink>
          <CtaLink to="cta">Sign Up</CtaLink>
        </Wrapper>
      </Headroom>
    );
  }
}

export default Header;
