import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import device from "../../../utils/device";
import Text from "../../Text";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 7rem;
  grid-template-rows: auto auto 1fr;
  justify-content: center;
  text-align: center;

  padding: 1rem 0.2rem;
`;

const Name = styled.div`
  padding-top: 0.8rem;
  /* font-size: 1.2rem; */
  font-weight: 600;
  color: ${props => props.theme.color.orange};
`;

const Title = styled.div``;

const TeamMember = ({ name, title, photo }) => {
  return (
    <Wrapper>
      <Img style={{display: "block", margin: "auto"}} resolutions={photo.resolutions} />
      <Name>{name}</Name>
      <Title>
        <Text>{title}</Text>
      </Title>
    </Wrapper>
  );
};

export default TeamMember;
