import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import device from "../../utils/device";
import SectionTitle from "../SectionTitle";
import Icomoon from "../Icomoon";

const GroupWrapper = styled.div`
  display: grid;
  text-align: center;
`;

const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  padding: 0.8rem 0 0.6rem 0;
`;

const GroupContent = styled.div`
  line-height: 1.2em;
`;

const GroupFeature = styled.p``;

const GroupIcon = styled(Icomoon)`
  font-size: 5rem;
`;

const FeatureGroup = ({ iconName, title, features }) => {
  const feats = features.map((f, index) => (
    <GroupFeature key={`gf-${index}`}>{f}</GroupFeature>
  ));
  return (
    <GroupWrapper>
      <GroupIcon>{iconName}</GroupIcon>
      <GroupTitle>{title}</GroupTitle>
      <GroupContent>{feats}</GroupContent>
    </GroupWrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  padding: ${props =>
    `${props.theme.sectionMarginTB}em ${props.theme.sectionMargin}em`};
  align-content: center;
  text-align: center;

  @media screen and (orientation: landscape), ${device.tablet} {
    padding: ${props =>
      `${props.theme.sectionMarginTBBig}rem ${props.theme.sectionMargin}rem`};
  }
`;

const Subtitle = styled.div`
  display: none;
  padding-bottom: 1.2rem;
  font-size: 1.2rem;

  @media screen and (orientation: landscape), ${device.tablet} {
    display: block;
  }
`;

const Groups = styled.div`
  display: grid;
  grid-row-gap: 3rem;
  padding-top: 0.4rem;

  @media screen and (orientation: landscape), ${device.tablet} {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    text-align: center;
    padding-top: 0;
  }
`;

const Features = ({ contentfulHomepage }) => {
  const {
    featuresTitle: title,
    featuresSubtitle: subtitle,
    featuresSets
  } = contentfulHomepage;

  const groups = featuresSets.map(({ title, iconName, features }) => (
    <FeatureGroup
      key={iconName}
      title={title}
      iconName={iconName}
      features={features}
    />
  ));

  return (
    <Wrapper id="features">
      <SectionTitle justify="center">{title}</SectionTitle>
      <Subtitle>{subtitle}</Subtitle>
      <Groups>{groups}</Groups>
    </Wrapper>
  );
};

export default Features;

export const featuresFragment = graphql`
  fragment Features on ContentfulHomepage {
    featuresTitle
    featuresSubtitle
    featuresSets {
      title
      iconName
      features
    }
  }
`;
