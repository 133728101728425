import { em } from "polished";

const size = {
  mobileS: em("320px"),
  mobileM: em("375px"),
  mobileL: em("425px"),
  tablet: em("768px"),
  notTablet: em("767px"),
  laptop: em("1024px"),
  laptopL: em("1200px"),
  desktop: em("2560px"),

  mobileLHeight: em("800px")
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,

  notTablet: `(max-width: ${size.notTablet})`,
  mobileSOnly: `(max-width: ${size.mobileS})`,

  mobileLHeight: `(min-height: ${size.mobileLHeight})`
};

export default device;
