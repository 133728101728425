// import TitilliumWebRegularWoff2 from "../fonts/TitilliumWeb-Regular.woff2";
// import TitilliumWebBoldWoff2 from "../fonts/TitilliumWeb-Bold.woff2";
// import TitilliumWebSemiBoldWoff2 from "../fonts/TitilliumWeb-SemiBold.woff2";
import TitilliumWebSemiBoldItalicWoff2 from "../fonts/TitilliumWeb-SemiBoldItalic.woff2";

import IcomoonWoff2 from "../fonts/icomoon.woff2";

export default {
  // TitilliumWebRegularWoff2,
  // TitilliumWebBoldWoff2,
  // TitilliumWebSemiBoldWoff2,
  TitilliumWebSemiBoldItalicWoff2,
  IcomoonWoff2
};
