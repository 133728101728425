import React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Span = styled.span``;

const Text = ({ children }) => {
  const content = children
    .replace(/&nbsp;/g, "\u00A0")
    .replace(/\&#44;/g, ",")
    .split("\\n")
    .map((item, key) => {
      return (
        <Span key={key}>
          {item}
          <br />
        </Span>
      );
    });
  return <Wrapper>{content}</Wrapper>;
};

export default Text;
