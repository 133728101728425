import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import fontFiles from "./fonts";
import testFont from "../fonts/TitilliumWeb-Regular.woff2";

import theme from "../utils/theme";

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit; }

  html {
    font-weight: 300;
  }

  @media screen and (min-width: 680px){
    html{
      font-size: 17px;
    }
  }
  @media screen and (min-width: 720px){
    html{
      font-size: 18px;
    }
  }
  @media screen and (min-width: 800px){
    html{
      font-size: 19px;
    }
  }
  @media screen and (min-width: 860px){
    html{
      font-size: 20px;
    }
  }
  @media screen and (min-width: 920px){
    html{
      font-size: 21px;
    }
  }
  @media screen and (min-width: 1100px){
    html{
      font-size: 22px;
    }
  }
  @media screen and (min-width: 1200px){
    html{
      font-size: calc( 23px + (52 - 23) * (100vw - 1200px) / (2880 - 1200) );
    }
  }

  body {
    box-sizing: border-box;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    color: ${theme.color.grey};
  }

  @font-face {
    font-family: "TitilliumWeb-SemiBoldItalic";
    font-style: normal;
    font-weight: normal;
    src: url(${fontFiles.TitilliumWebSemiBoldItalicWoff2}) format("woff2");
  }

  @font-face {
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    src: url(${fontFiles.IcomoonWoff2}) format("woff2");
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    
    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .gatsby-image-outer-wrapper {
    height: 100%;
  }

  .headroom--unpinned {
    display: none;
  }
`;

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="Jumpn"
      meta={[
        { name: "charset", content: "UTF-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1.0" },
        {
          name: "description",
          content:
            "Jumpn is a mobile solution to help you run your entire activity business and empower your participants with a better way to learn and follow their passions."
        },
        {
          name: "keywords",
          content:
            "jumpn, activity, platform, mobile, app, leader, teacher, trainer"
        }
      ]}
    >
      <html lang="en" />
      
    </Helmet>
    <GlobalStyle />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </div>
);

TemplateWrapper.propTypes = {
  children: PropTypes.object
};

export default TemplateWrapper;
