import React from "react";
import styled from "styled-components";

import Slider from "./PriceCalculatorSlider";

const Wrapper = styled.div`
  display: grid;
  grid-area: calc;
  /* grid-template-columns: minmax(auto, 400px); */
  grid-template-columns: 1fr;
  justify-content: center;
  text-align: left;
`;

const Intro = styled.div`
  color: ${props => props.theme.color.white};
  margin-bottom: 1.2em;
`;

const Income = styled.div`
  color: ${props => props.theme.color.white};
  font-weight: 600;
`;

const Takeaway = styled.div`
  color: ${props => props.theme.color.white};
  font-weight: 600;
  margin-top: 1.2em;
`;

const renderIncome = value => {
  return <Income>Your monthly revenue: ${value}</Income>;
};

const Price = styled.div`
  color: ${props => props.theme.color.white};
  font-weight: 600;
`;

const renderPrice = income => {
  const brackets = [
    { start: 0, end: 1000, rate: 4.5 },
    { start: 1000, end: 2000, rate: 3.5 },
    { start: 2000, end: 3000, rate: 3.0 },
    { start: 3000, end: 5000, rate: 2.5 },
    { start: 5000, end: 10000, rate: 2.0 },
    { start: 10000, end: Number.MAX_SAFE_INTEGER, rate: 1.5 }
  ];

  const value = brackets
    .map(bracket => {
      if (income > bracket.start) {
        const bracketValue = Math.min(
          bracket.end - bracket.start,
          income - bracket.start
        );
        return Math.round((bracketValue * bracket.rate) / 100);
      } else {
        return 0;
      }
    })
    .reduce((acc, value) => acc + value, 0);

  return <Price>Jumpn fee: {value === 0 ? "FREE" : "$" + value}</Price>;
};

const renderTakeaway = income => {
  const content = (income => {
    if (income == 0)
      return "FREE when getting started with the app and for free activities.";
    if (income < 2000)
      return "Enjoy a super low monthly price with all the features of the app.";
    if (income < 3000)
      return "Save even more money and let us help you grow your business.";
    if (income < 5000)
      return "Get competitive rates adjusted to your monthly revenue.";
    return "Continue to be rewarded with even lower percentage monthly fees as you grow.";
  })(income);
  return <Takeaway>{content}</Takeaway>;
};

class PriceCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { income: 0 };
    this.onUpdate = this.onUpdate.bind(this);
  }

  onUpdate = values => {
    this.setState({ income: values[0] });
  };

  render() {
    const {
      props: { intro },
      state: { income }
    } = this;

    return (
      <Wrapper>
        <Intro>{intro}</Intro>
        {renderIncome(income)}
        <Slider onUpdate={this.onUpdate} />
        {renderPrice(income)}
        {renderTakeaway(income)}
      </Wrapper>
    );
  }
}

export default PriceCalculator;
