import React from "react";
import { graphql } from "gatsby";
import styled, { withTheme } from "styled-components";

import device from "../../utils/device";
import ImageSection from "../ImageSection";
import SectionTitle from "../SectionTitle";
import CtaForm from "./cta/CtaForm";
import Text from "../Text";

const Wrapper = styled.div`
  display: grid;

  @media screen and (orientation: landscape), ${device.tablet} {
    width: 50%;
    max-width: 800px;
  }
`;

const Intro = styled.div`
  color: ${props => props.theme.color.white};
  white-space: pre-wrap;
`;

const Cta = ({
  contentfulHomepage,
  ctaImagePortrait,
  ctaImageLandscape,
  theme
}) => {
  const {
    ctaTitle: title,
    ctaIntro: intro,
    ctaEmail: emailPlaceholder,
    ctaSubmit: submitLabel,
    ctaConfirmationMessage: confirmationMessage
  } = contentfulHomepage;

  return (
    <ImageSection
      id="cta"
      portrait={ctaImagePortrait}
      landscape={ctaImageLandscape}
    >
      <SectionTitle color={theme.color.white}>{title}</SectionTitle>
      <Wrapper>
        <Intro>
          <Text>{intro.txt}</Text>
        </Intro>

        <CtaForm
          emailPlaceholder={emailPlaceholder}
          submitLabel={submitLabel}
          confirmationMessage={confirmationMessage}
        />
      </Wrapper>
    </ImageSection>
  );
};

export default withTheme(Cta);

export const ctaFragment = graphql`
  fragment Cta on ContentfulHomepage {
    ctaTitle
    ctaIntro {
      txt: ctaIntro
    }
    ctaEmail
    ctaSubmit
    ctaConfirmationMessage
  }
`;
