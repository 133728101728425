import React from "react";
import styled from "styled-components";

import device from "../../../utils/device";
import Icomoon from "../../Icomoon";

import Text from "../../Text";

const Wrapper = styled.div`
  display: grid;
  grid-area: box;
  /* grid-template-columns: minmax(auto, 400px); */
  grid-template-columns: 1fr;
  justify-content: center;
  text-align: center;

  padding-top: 3rem;

  @media screen and (orientation: landscape), ${device.tablet} {
    grid-template-columns: auto;
    align-self: center;
    padding: 0;
  }

  @media screen and ${device.laptop} {
    padding: 0 calc(100vw/22);
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${props => props.theme.color.white};
  padding: 1rem 0;
  background-color: ${props => props.theme.color.orange};
`;

const ContentWrapper = styled.div`
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.orange};
`;

const Notes = styled.div`
  display: grid;
  grid-row-gap: 0.4rem;
  padding: 1.6rem 0 1.5rem 0;
  margin: 0 1rem;
  text-align: left;
  font-size: 0.8rem;
`;

const Note = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

const NoteIcon = styled(Icomoon)`
  font-size: 1.3rem;
  padding-right: 0.4rem;
`;
const NoteText = styled.div``;

const noteRenderer = (note, index) => (
  <Note key={`note-${index}`}>
    <NoteIcon>check</NoteIcon>
    <NoteText>
      <Text>{note}</Text>
    </NoteText>
  </Note>
);

const PriceBox = ({ title, price, caption, notes }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <ContentWrapper>
        <Notes>{notes.map((n, index) => noteRenderer(n, index))}</Notes>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PriceBox;
