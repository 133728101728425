import React from "react";
import styled from "styled-components";
import createReactClass from "create-react-class";
import Carousel from "nuka-carousel";

const ButtonList = styled.ul`
  position: relative;
  top: 60px;
`;

const ButtonListItem = styled.li`
  list-style-type: none;
  display: inline-block;
`;

const Button = styled.button`
  border: ${props =>
    props.active ? `1px solid ${props.theme.color.orange}` : `1px solid grey`};
  border-radius: 50%;
  outline: none;
  background-color: ${props =>
    props.active ? props.theme.color.orange : props.theme.color.white};
  padding: 0.4rem;
  margin: 0.5rem;
  outline: 0;
  cursor: pointer;
`;

class PagingDots extends React.Component {
  getIndexes(count, inc) {
    const arr = [];
    for (let i = 0; i < count; i += inc) {
      arr.push(i);
    }
    return arr;
  }

  render() {
    const indexes = this.getIndexes(
      this.props.slideCount,
      this.props.slidesToScroll
    );
    return (
      <ButtonList>
        {indexes.map(index => {
          return (
            <ButtonListItem key={index}>
              <Button
                aria-label={`Slide ${index}`}
                active={this.props.currentSlide === index}
                onClick={this.props.goToSlide.bind(null, index)}
              />
            </ButtonListItem>
          );
        })}
      </ButtonList>
    );
  }
}

const ReviewCarousel = createReactClass({
  getInitialState() {
    return { slideIndex: 1 };
  },

  render() {
    return (
      <Carousel
        ref="carousel"
        slideWidth={this.props.slideWidth}
        cellSpacing={30}
        cellAlign="center"
        heightMode="first"
        renderBottomCenterControls={({
          slideCount,
          slidesToScroll,
          currentSlide,
          goToSlide
        }) => (
          <PagingDots
            slideCount={slideCount}
            slidesToScroll={slidesToScroll}
            currentSlide={currentSlide}
            goToSlide={goToSlide}
          />
        )}
        renderCenterRightControls={() => null}
        renderCenterLeftControls={() => null}
        slideIndex={this.state.slideIndex}
        afterSlide={slideIndex => this.setState({ slideIndex })}
      >
        {this.props.children}
      </Carousel>
    );
  }
});

export default ReviewCarousel;
