import React from "react";
import { graphql } from "gatsby";
import styled, { withTheme } from "styled-components";
import MediaQuery from "react-responsive";

import device from "../../utils/device";
import ImageSection from "../ImageSection";
import SectionTitle from "../SectionTitle";
import PriceBox from "./price/PriceBox";
import PriceCalculator from "./price/PriceCalculator";
import Text from "../Text";

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "title"
    "intro"
    "calc"
    "box";

  @media screen and (orientation: landscape), ${device.tablet} {
    grid-template-areas:
      "title title"
      "intro intro"
      "calc box";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-column-gap: 1rem;
    height: 100%;
    align-items: start;
  }
`;

const Intro = styled.div`
  grid-area: intro;
  color: ${props => props.theme.color.white};

  @media screen and (orientation: landscape) and ${device.mobileL} {
    width: 100%;
  }

  @media screen and (orientation: landscape) and ${device.laptop} {
    width: 100%;
  }

  margin-bottom: 1em;
`;

const IntroPara = styled.p`
  padding-bottom: 0.4rem;
`;

const renderIntroContent = content => {
  const paragraphs = content.split("\n").filter(txt => txt.length > 2);
  const renderPara = (p, index) => <IntroPara key={`price-intro-${index}`}>{p}</IntroPara>;

  return paragraphs.map((p, index) => renderPara(p, index));
}

const Price = ({
  contentfulHomepage,
  priceImagePortrait,
  priceImageLandscape,
  theme
}) => {
  const {
    priceTitle: title,
    priceShortTitle: shortTitle,
    priceIntro: intro,
    priceShortIntro: shortIntro,
    priceSubtitle: subtitle,
    pricePercent: price,
    priceCaption: caption,
    priceNotes: notes,
    priceCalcIntro: calcIntro
  } = contentfulHomepage;

  return (
    <ImageSection
      id="price"
      portrait={priceImagePortrait}
      landscape={priceImageLandscape}
      grid={false}
    >
      <Wrapper>
        <SectionTitle color={theme.color.white}>
          <MediaQuery query={`(orientation: landscape), ${device.tablet}`}>
            {matches => {
              if (matches) {
                return <Text>{title}</Text>;
              } else {
                return shortTitle;
              }
            }}
          </MediaQuery>
        </SectionTitle>
        <Intro>
          <MediaQuery query={`(orientation: landscape), ${device.tablet}`}>
            {matches => {
              if (matches) {
                return renderIntroContent(intro.txt);
              } else {
                return renderIntroContent(shortIntro.txt);
              }
            }}
          </MediaQuery>
        </Intro>
        <PriceBox
          title={subtitle}
          price={price}
          caption={caption}
          notes={notes}
        />
        <PriceCalculator intro={calcIntro} />
      </Wrapper>
    </ImageSection>
  );
};

export default withTheme(Price);

export const priceFragment = graphql`
  fragment Price on ContentfulHomepage {
    priceTitle
    priceShortTitle
    priceIntro {
      txt: priceIntro
    }
    priceShortIntro {
      txt: priceShortIntro
    }
    priceSubtitle
    pricePercent
    priceCaption
    priceNotes
    priceCalcIntro
  }
`;
