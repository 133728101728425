import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import MediaQuery from "react-responsive";

import SectionTitle from "../SectionTitle";
import device from "../../utils/device";

const Wrapper = styled.section`
  display: grid;
  grid-template-areas:
    "title"
    "phone"
    "content";

  margin-left: ${props => `${props.theme.sectionMargin}rem`};
  margin-bottom: ${props => `${props.theme.sectionMarginTB}rem`};

  @media screen and (orientation: landscape), ${device.tablet} {
    grid-template-columns: 40% 60%;
    grid-template-rows: auto auto;
    grid-template-areas:
      "title phone"
      "title phone";
    margin-bottom: 0;
  }
`;

const TitleWrapper = styled.div`
  grid-area: title;
  margin-top: ${props => `${props.theme.sectionMarginTB}rem`};

  @media screen and (orientation: landscape), ${device.tablet} {
    margin-top: ${props => `${props.theme.sectionMarginTBBig}rem`};
  }
`;

const ImageWrapper = styled.div`
  grid-area: phone;

  @media screen and (orientation: landscape), ${device.tablet} {
    align-self: end;
  }
`;

const Content = styled.p`
  grid-area: content;
  margin-right: ${props => `${props.theme.sectionMargin}rem`};

  @media screen and (orientation: landscape), ${device.tablet} {
    display: none;
    margin-right: 0;
  }
`;

const InlineContent = styled.p`
  display: none;
  @media screen and (orientation: landscape), ${device.tablet} {
    display: block;
    margin-bottom: ${props => `${props.theme.sectionMarginTBBig}rem`};
  }
`;

const Intro = props => {
  const { contentfulHomepage, introImageLandscape, introImagePortrait } = props;
  const { introTitle: title, introContent: content } = contentfulHomepage;

  return (
    <Wrapper id="intro">
      <TitleWrapper>
        <SectionTitle>{title}</SectionTitle>
        <InlineContent>{content.txt}</InlineContent>
      </TitleWrapper>
      <ImageWrapper>
        <MediaQuery query={`(orientation: landscape), ${device.tablet}`}>
          <Img alt="Activities" fluid={introImageLandscape.childImageSharp.fluid} />
        </MediaQuery>
        <MediaQuery query={`(orientation: portrait) and ${device.notTablet}`}>
          <Img alt="Activities" fluid={introImagePortrait.childImageSharp.fluid} />
        </MediaQuery>
      </ImageWrapper>
      <Content>{content.txt}</Content>
    </Wrapper>
  );
};

export default Intro;

export const introFragment = graphql`
  fragment Intro on ContentfulHomepage {
    introTitle
    introContent {
      txt: introContent
    }
  }
`;
