import React from "react";
import styled from "styled-components";
import Menu from "react-burger-menu/lib/menus/slide";
import Scrollchor from "react-scrollchor";
import Icomoon from "../Icomoon";

import theme from "../../utils/theme";

const Icon = styled(Icomoon)`
  font-size: 2rem;
`;

const SectionLink = styled(Scrollchor)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.4em;
  color: ${props => props.theme.color.grey};

  &:hover {
    color: ${props => props.theme.color.orange};
  }
`;

const CtaLink = styled(SectionLink)``;

const renderSectionLink = (anchor, label, afterAnimate) => (
  <SectionLink to={anchor} afterAnimate={afterAnimate} disableHistory>
    {label}
  </SectionLink>
);

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  render() {
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
        styles={styles}
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        customBurgerIcon={<Icon>menu</Icon>}
        right
      >
        {renderSectionLink("intro", "About", () => this.closeMenu())}
        {renderSectionLink("vision", "Vision", () => this.closeMenu())}
        {renderSectionLink("features", "Features", () => this.closeMenu())}
        {renderSectionLink("reviews", "Testimonials", () => this.closeMenu())}
        {renderSectionLink("screenshots", "Screenshots", () =>
          this.closeMenu()
        )}
        {renderSectionLink("price", "Price", () => this.closeMenu())}
        {renderSectionLink("team", "Team", () => this.closeMenu())}

        <CtaLink to="cta" afterAnimate={() => this.closeMenu()} disableHistory>
          Sign Up
        </CtaLink>
      </Menu>
    );
  }
}

export default MobileMenu;

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "28px",
    top: "28px"
  },
  bmBurgerBars: {
    background: theme.color.orange
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: theme.color.orange
  },
  bmMenu: {
    background: theme.color.white,
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em"
  },
  bmMenuWrap: {
    width: "80%",
    maxWidth: "400px"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    padding: "0.2em"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)"
  }
};
