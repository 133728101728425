import React from "react";
import styled from "styled-components";

import device from "../utils/device";

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  justify-items: ${props => props.justify};
`;

const Title = styled.div`
  font-family: "TitilliumWeb-SemiBoldItalic";
  font-size: 1.9rem;
  line-height: 1em;
  letter-spacing: 0.1rem;

  color: ${props => props.color || props.theme.color.black};
`;

const Underline = styled.div`
  height: 0.2rem;
  width: 2.2rem;
  margin: 0.5rem 0.2rem ${props => props.theme.sectionMarginTB}rem 0.2rem;
  background-color: ${props => props.theme.color.orange};

  @media screen and (orientation: landscape), ${device.tablet} {
    margin: 1rem 0.2rem 0.9rem 0.2rem;
  }
`;

const SectionTitle = ({ children, justify = "start", color }) => {
  return (
    <Wrapper justify={justify}>
      <Title color={color}>{children}</Title>
      <Underline />
    </Wrapper>
  );
};

export default SectionTitle;
