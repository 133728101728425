import React from "react";

import { graphql } from "gatsby";

import styled from "styled-components";
import MediaQuery from "react-responsive";

import Layout from "../components/layout";
import Header from "../components/homepage/Header";
import MobileMenu from "../components/homepage/MobileMenu";

import HeroVideo from "../components/homepage/HeroVideo";
import Intro from "../components/homepage/Intro";
import Vision from "../components/homepage/Vision";
import Features from "../components/homepage/Features";
import Review from "../components/homepage/Review";
import Screenshots from "../components/homepage/Screenshots";
import Price from "../components/homepage/Price";
import Team from "../components/homepage/Team";
import Cta from "../components/homepage/Cta";
import Footer from "../components/homepage/Footer";

const Page = styled.div``;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
`;

const IndexPage = props => {
  const data = props.data;

  return (
    <Layout>
      <Page id="outer-container">
        <MediaQuery query={`(orientation: portrait)`}>
          <MobileMenu />
        </MediaQuery>
        <MediaQuery query={`(orientation: landscape)`}>
          <Header />
        </MediaQuery>

        <Wrapper id="page-wrap">
          <HeroVideo {...data} />
          <Intro {...data} />
          <Vision {...data} />
          <Features {...data} />
          <Review {...data} />
          <Screenshots {...data} />
          <Price {...data} />
          <Team {...data} />
          <Cta {...data} />
          <Footer {...data} />
        </Wrapper>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query homepage {
    contentfulHomepage(contentful_id: { eq: "6uD4yzj280sycyQQWUw6C4" }) {
      ...HeroVideo
      ...Intro
      ...Vision
      ...Features
      ...Review
      ...Screenshots
      ...Price
      ...Team
      ...Cta
      ...Footer
    }
    introImageLandscape: file(relativePath: { regex: "/phone_activities_landscape_en/" })
    {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    introImagePortrait: file(relativePath: { regex: "/phone_activities_portrait_en/" })
    {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    visionImagePortrait: file(relativePath: { regex: "/vision_portrait/" })
    {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    visionImageLandscape: file(relativePath: { regex: "/vision_landscape/" })
    {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    reviewImagePortrait: file(relativePath: { regex: "/testimonials_portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    reviewImageLandscape: file(relativePath: { regex: "/testimonials_landscape/" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    priceImagePortrait: file(relativePath: { regex: "/price_portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    priceImageLandscape: file(relativePath: { regex: "/price_landscape/" }) {
      childImageSharp {
          fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaImagePortrait: file(relativePath: { regex: "/cta_portrait/" }) {
      childImageSharp {
          fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaImageLandscape: file(relativePath: { regex: "/cta_landscape/" }) {
      childImageSharp {
          fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
