import React from "react";
import styled from "styled-components";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url =
  "https://jumpn.us13.list-manage.com/subscribe/post?u=eea64f8eb572c869323f8c880&id=98cacd5290";

const Wrapper = styled.div`
  display: grid;
  padding: 1rem 0;
  justify-items: center;
`;

const Form = styled.form``;

const EmailInput = styled.input`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  padding: 0.2rem 0;
  margin-bottom: 1rem;
  opacity: 0.9;
`;

const SubmitButton = styled.button`
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.orange};
  border-radius: 3px;
  box-shadow: none;
  border-color: transparent;
  cursor: pointer;

  border: 1px solid ${props => props.theme.color.orange};

  &:hover {
    color: ${props => props.theme.color.orange};
    background-color: ${props => props.theme.color.white};
  }
`;

const ConfirmationMessage = styled.div`
  margin-top: 1rem;
  padding: 0.3rem 0;
  color: ${props => props.theme.color.orange};
  background-color: ${props => props.theme.color.white};
  text-align: center;
`;

class CtaForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    this.props.subscribe({
      EMAIL: this.state.email
    });
    event.preventDefault();
  }

  render() {
    if (this.props.status != null) {
      return (
        <ConfirmationMessage>
          {this.props.confirmationMessage}
        </ConfirmationMessage>
      );
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Wrapper>
          <EmailInput
            type="email"
            aria-label="email"
            placeholder={this.props.emailPlaceholder}
            value={this.state.email}
            onChange={this.handleChangeEmail}
            required
          />
          <SubmitButton aria-label="Submit" type="submit">
            {this.props.submitLabel}
          </SubmitButton>
        </Wrapper>
      </Form>
    );
  }
}

const MailChimpCtaForm = ({
  emailPlaceholder,
  submitLabel,
  confirmationMessage
}) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => {
      return (
        <CtaForm
          subscribe={subscribe}
          emailPlaceholder={emailPlaceholder}
          submitLabel={submitLabel}
          confirmationMessage={confirmationMessage}
          status={status}
          message={message}
        />
      );
    }}
  />
);

export default MailChimpCtaForm;
