import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import Img from "gatsby-image";

import SectionTitle from "../SectionTitle";
import ScreenCarousel from "./screenshots/ScreenCarousel";

import device from "../../utils/device";
import theme from "../../utils/theme";

const Wrapper = styled.section`
  display: grid;

  padding: ${props => `${props.theme.sectionMarginTB}rem 0`};

  @media screen and (orientation: landscape), ${device.tablet} {
    padding: ${props => `${props.theme.sectionMarginTBBig}rem 0`};
  }
`;

const CarouselWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 3.5rem;
`;

const Screenshots = ({ contentfulHomepage }) => {
  const {
    screenshotTitle: title,
    screenshotImages: screens
  } = contentfulHomepage;

  const screenshotContent = screens.map((s, index) => (
    <Img
      style={{ border: `1px solid ${theme.color.lightGrey}` }}
      key={`screen-${index}`}
      fluid={s.fluid}
    />
  ));

  return (
    <Wrapper id="screenshots">
      <SectionTitle justify="center">{title}</SectionTitle>
      <CarouselWrapper>
        <MediaQuery query="(orientation: portrait)">
          <ScreenCarousel slideWidth={0.5}>{screenshotContent}</ScreenCarousel>
        </MediaQuery>
        <MediaQuery query={`(orientation: landscape)`}>
          <ScreenCarousel slideWidth={0.25}>{screenshotContent}</ScreenCarousel>
        </MediaQuery>
      </CarouselWrapper>
    </Wrapper>
  );
};

export default Screenshots;

export const screenshotsFragment = graphql`
  fragment Screenshots on ContentfulHomepage {
    screenshotTitle
    screenshotImages {
      fluid(maxWidth: 600, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;
