import React from "react";
import { graphql } from "gatsby";
import styled, { withTheme } from "styled-components";
import MediaQuery from "react-responsive";
import Img from "gatsby-image";

import device from "../../utils/device";
import ImageSection from "../ImageSection";
import SectionTitle from "../SectionTitle";
import ReviewCarousel from "./review/ReviewCarousel";

const Testimonial = styled.div`
  display: grid;
  grid-template-areas:
    "photo"
    "content";
  grid-template-rows: auto 1fr;

  height: calc(72vh);
  max-height: 300px;

  @media screen and ${device.tablet} {
    grid-template-areas: "photo content";
    text-align: left;
    justify-content: start;

    height: auto;
    max-height: inherit;
  }
`;

const LeaderPhoto = styled.div`
  grid-area: photo;

  @media screen and ${device.tablet} {
    padding-right: 1rem;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: content;

  grid-template-areas:
    "name"
    "title"
    "review";
  grid-template-rows: auto auto 1fr;

  color: ${props => props.theme.color.white};
  text-align: center;

  @media screen and ${device.tablet} {
    grid-template-areas:
      "review"
      "name"
      "title";
    grid-template-rows: auto auto 1fr;
    text-align: left;
  }
`;

const LeaderName = styled.div`
  grid-area: name;
  font-weight: 600;
  font-size: 1.1rem;
  padding-top: 0.6rem;

  @media screen and ${device.tablet} {
    padding: 0;
  }
`;

const LeaderTitle = styled.div`
  grid-area: title;
  padding-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 1.2em;

  @media screen and ${device.tablet} {
    padding-bottom: 0;
    align-self: start;
    font-size: 0.9em;
  }
`;

const LeaderContent = styled.blockquote`
  grid-area: review;
  font-style: italic;

  @media screen and ${device.tablet} {
    padding-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  &:before,
  &:after {
    content: '"';
  }
`;

const testimonialRender = ({ leader, content }) => (
  <Testimonial key={`testimonial-${leader.name}`}>
    <LeaderPhoto>
      <Img style={{display: "block", margin: "auto"}} resolutions={leader.photo.resolutions} />
    </LeaderPhoto>
    <ContentWrapper>
      <LeaderName>{leader.name}</LeaderName>
      <LeaderTitle>{leader.title}</LeaderTitle>
      <LeaderContent>{content}</LeaderContent>
    </ContentWrapper>
  </Testimonial>
);

const Subtitle = styled.div`
  display: none;
  color: ${props => props.theme.color.white};
  justify-self: center;
  font-size: 1.2rem;
  padding-bottom: 2rem;

  @media screen and (orientation: landscape), ${device.tablet} {
    display: block;
  }
`;

const CarouselWrapper = styled.div`
  overflow: hidden;
`;

const TestimonialsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  height: inherit;
  grid-row-gap: 1rem;
`;

const Review = ({
  contentfulHomepage,
  reviewImagePortrait,
  reviewImageLandscape,
  theme
}) => {
  const {
    reviewTitle: title,
    reviewSubtitle: subtitle,
    reviewShortTitle: shortTitle,
    reviewTestimonials: testimonials
  } = contentfulHomepage;

  const testimonialsContent = testimonials.map(t => testimonialRender(t));

  return (
    <ImageSection
      id="reviews"
      portrait={reviewImagePortrait}
      landscape={reviewImageLandscape}
    >
      <SectionTitle justify="center" color={theme.color.white}>
        <MediaQuery query="(orientation: portrait)">
          {matches => {
            if (matches) {
              return shortTitle;
            } else {
              return title;
            }
          }}
        </MediaQuery>
      </SectionTitle>
      <Subtitle>{subtitle}</Subtitle>

      <MediaQuery query={device.tablet}>
        <TestimonialsWrapper>{testimonialsContent}</TestimonialsWrapper>
      </MediaQuery>
      <MediaQuery query={device.notTablet}>
        <CarouselWrapper>
          <ReviewCarousel>{testimonialsContent}</ReviewCarousel>
        </CarouselWrapper>
      </MediaQuery>
    </ImageSection>
  );
};

export default withTheme(Review);

export const reviewFragment = graphql`
  fragment Review on ContentfulHomepage {
    reviewTitle
    reviewShortTitle
    reviewSubtitle
    reviewTestimonials {
      content
      leader {
        name
        title
        photo {
          resolutions(width: 100, height: 100, quality: 90) {
            ...GatsbyContentfulResolutions_withWebp_noBase64
          }
        }
      }
    }
  }
`;
