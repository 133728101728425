import React, { Fragment } from "react";
import { graphql } from "gatsby";
import styled, { css, keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import MediaQuery from "react-responsive";
import Scrollchor from "react-scrollchor";

import device from "../../utils/device";
import Icomoon from "../Icomoon";
import logo from "../../images/logo_white2_shadow.png";

const Poster = styled.picture`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const PosterImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const sourceRenderer = (srcSet, sizes, type) => (
  <source srcSet={srcSet} sizes={sizes} type={type} />
);

const VideoPoster = ({ img }) => (
  <Poster>
    {sourceRenderer(img.srcSetWebp, img.sizes, "image/webp")}
    {sourceRenderer(img.srcSet, img.sizes, "image/jpg")}
    <PosterImg alt="cover" src={img.src} />
  </Poster>
);

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const Video = styled.video`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: transparent;
`;

const Overlay = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 3;

  @media screen and (orientation: landscape) {
    grid-template-rows: 1fr auto;
  }
`;

const OverlayHeader = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 80px;
  z-index: 4;
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-row-gap: 0.2rem;
  justify-items: center;
  align-content: center;
  padding-bottom: 6rem;

  @media screen and (orientation: portrait) and ${device.mobileLHeight} {
    padding-bottom: 8rem;
  }

  @media screen and (orientation: landscape) {
    /* align-self: end; */
    padding-bottom: 0;
    padding-top: 2.5rem;
  }
`;

const Title = styled.h2`
  font-size: 1.7rem;
  text-transform: uppercase;
  font-family: "TitilliumWeb-SemiBoldItalic";
  opacity: 0;

  /* line-height: 1em; */
  color: ${props => props.theme.color.white};
  text-shadow: 2px 2px ${props => props.theme.color.black};
  text-align: center;
  line-height: 1em;

  @media screen and (orientation: portrait) and ${device.tablet} {
    font-size: 3rem;
  }
`;

const fadeInAnimation = keyframes`${fadeIn}`;
const animationRule = css`
  3s ${fadeInAnimation};
`;


const Title1 = styled(Title)`
  animation: ${animationRule};
  animation-fill-mode: forwards;
`;

const Title2 = styled(Title)`
  animation: 3s ${fadeInAnimation};
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;

const Logo = styled.img`
  align-self: start;
  padding-top: calc(50vh - 70px);
  justify-self: center;

  width: 140px;

  opacity: 0;
  animation: 3s ${fadeInAnimation};
  animation-fill-mode: forwards;

  @media screen and (orientation: landscape) {
    display: none;
  }

  @media screen and ${device.mobileLHeight} {
    width: 170px;
    padding-top: calc(50vh - 100px);
  }

  @media screen and ${device.tablet} {
    width: 260px;
    padding-top: calc(50vh - 90px);
  }
`;

const ArrowDownLink = styled(Scrollchor)`
  align-self: end;
  justify-self: center;
  text-decoration: none;
  padding-bottom: 3rem;

  @media screen and (orientation: landscape) {
    padding-bottom: 2rem;
  }
`;

const ArrowDownIcon = styled(Icomoon)`
  color: ${props => props.theme.color.white};
  text-shadow: 1px 1px ${props => props.theme.color.black};
  font-size: 1.8rem;
`;

const HeroVideo = props => {
  const { contentfulHomepage, logoImage } = props;

  const {
    videoTitle1: title1,
    videoTitle2: title2,
    videoLandscapeCover: landscapeCover,
    videoLandscapes: landscapes,
    videoPortraitCover: portraitCover,
    videoPortraits: portraits
  } = contentfulHomepage;

  const sourceRenderer = (id, url, contentType) => (
    <source key={id} src={url} type={contentType} />
  );

  const renderVideo = sources => (
    <Video playsInline loop muted autoPlay>
      {sources.map(s => sourceRenderer(s.id, s.file.url, s.file.contentType))}
    </Video>
  );

  return (
    <Wrapper>
      <MediaQuery query="(orientation: portrait)">
        <Fragment>
          <VideoPoster img={portraitCover.fluid} />
          {renderVideo(portraits)}
        </Fragment>
      </MediaQuery>
      <MediaQuery query="(orientation: landscape)">
        <Fragment>
          <VideoPoster img={landscapeCover.fluid} />
          {renderVideo(landscapes)}
        </Fragment>
      </MediaQuery>

      <Overlay>
        <Logo alt="Logo" src={logo} />
        <TitleWrapper>
          <Title1>{title1}</Title1>
          <Title2>{title2}</Title2>
        </TitleWrapper>
        {/* <ArrowDownLink to="intro" disableHistory>
          <ArrowDownIcon>arrowdowncircle</ArrowDownIcon>
        </ArrowDownLink> */}
      </Overlay>
    </Wrapper>
  );
};

export default HeroVideo;

export const heroVideoFragment = graphql`
  fragment HeroVideo on ContentfulHomepage {
    videoTitle1
    videoTitle2
    videoLandscapeCover {
      fluid(maxWidth: 1200, quality: 85) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    videoLandscapes {
      id
      file {
        url
        contentType
      }
    }
    videoPortraitCover {
      fluid(maxWidth: 800, quality: 85) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    videoPortraits {
      id
      file {
        url
        contentType
      }
    }
  }
`;
