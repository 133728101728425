import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import device from "../../utils/device";
import Icomoon from "../Icomoon";

const Wrapper = styled.footer`
  display: grid;
  grid-template-areas: "contact" "social" "copyright";
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 ${props => `${props.theme.sectionMargin}em`};
  padding: 1rem 0;
  grid-row-gap: 1rem;

  @media screen and (orientation: landscape), ${device.tablet} {
    grid-template-areas: "copyright contact social";
    grid-template-columns: 1fr auto auto;
    grid-column-gap: 1rem;
    text-align: left;
  }
`;

const Copyright = styled.div`
  grid-area: copyright;
  font-size: 0.8rem;
`;

const ContactLink = styled.a`
  grid-area: contact;
  font-size: 1.2rem;
  color: ${props => props.theme.color.orange};
  text-decoration: none;

  @media screen and (orientation: landscape), ${device.tablet} {
    font-size: 1rem;
  }
`;

const Social = styled.div`
  grid-area: social;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 0.3rem;
`;

const SocialLink = styled.a`
  text-decoration: none;
`;

const SocialIcon = styled(Icomoon)`
  font-size: 2rem;

  @media screen and (orientation: landscape), ${device.tablet} {
    font-size: 1.2rem;
  }
`;

const TwitterIcon = styled(SocialIcon)`
  margin-left: 0.3rem;
`;

const Footer = ({ contentfulHomepage }) => {
  const {
    footerContactLabel: contactLabel,
    footerContactEmail: contactEmail,
    footerContactEmailSubject: emailSubject
  } = contentfulHomepage;
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <Copyright>&copy; {year} Jumpn Limited</Copyright>
      <ContactLink href={`mailto:${contactEmail}?subject=${emailSubject}`}>
        {contactLabel}
      </ContactLink>
      <Social>
        <SocialLink target="new" href="https://www.facebook.com/Jumpn-636199323410513"><SocialIcon>fb</SocialIcon></SocialLink>
        <SocialLink target="new" href="https://www.instagram.com/withjumpn"><SocialIcon>instagram</SocialIcon></SocialLink>
        <SocialLink target="new" href="https://www.twitter.com/withjumpn"><TwitterIcon>twitter</TwitterIcon></SocialLink>
      </Social> 
    </Wrapper>
  );
};

export default Footer;

export const footerFragment = graphql`
  fragment Footer on ContentfulHomepage {
    footerContactLabel
    footerContactEmail
    footerContactEmailSubject
  }
`;
