import React from "react";
import styled from "styled-components";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";

import theme from "../../../utils/theme";

const Wrapper = styled.div`
padding: 14px 0 24px 0;
`;

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps
}) => {
  return (
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: "-11px",
        marginTop: "-6px",
        zIndex: 2,
        width: 24,
        height: 24,
        cursor: "pointer",
        border: `1px solid ${theme.color.white}`,
        borderRadius: "50%",
        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
        backgroundColor: theme.color.orange
      }}
      {...getHandleProps(id)}
    />
  );
};

const sliderStyle = {
  position: "relative",
  width: "100%"
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 14,
  borderRadius: 7,
  cursor: "pointer",
  backgroundColor: theme.color.white
};

const CalcSlider = ({ onUpdate }) => {
  const domain = [0, 10000];

  return (
    <Wrapper>
      <Slider
        rootStyle={sliderStyle}
        mode={1}
        step={500}
        values={[0]}
        domain={domain}
        onUpdate={onUpdate}
      >
        <Rail>
          {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
      </Slider>
    </Wrapper>
  );
};

export default CalcSlider;
