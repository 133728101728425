import React from "react";
import styled from "styled-components";

const Icon = ({ className, children }) => (
  <span className={`icon- ${className}`}>{children}</span>
);

const Icomoon = styled(Icon)`
  color: ${props => props.theme.color.orange};
  font-size: ${props => props.size};
  padding: ${props => props.padding || 0};
`;

export default Icomoon;
