import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import MediaQuery from "react-responsive";

import device from "../utils/device";

const Wrapper = styled.section`
  display: grid;
`;

const BackgroundImage = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 1;

  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  display: ${props => (props.grid ? `grid` : `block`)};
  align-content: start;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 2;

  margin: ${props =>
    `${props.theme.sectionMarginTB}rem ${props.theme.sectionMargin}rem`};

  @media screen and (orientation: landscape), ${device.tablet} {
    margin: ${props =>
      `${props.theme.sectionMarginTBBig}rem ${props.theme.sectionMargin}rem`};
  }
`;

const ImageSection = ({ id, grid = true, portrait, landscape, children }) => (
  <Wrapper id={id}>
    <BackgroundImage>
      <MediaQuery query={`(orientation: landscape), ${device.tablet}`}>
        <Img alt="" style={{ height: "100%" }} fluid={landscape.childImageSharp.fluid} />
      </MediaQuery>
      <MediaQuery query={`(orientation: portrait) and ${device.notTablet}`}>
        <Img alt="" style={{ height: "100%" }} fluid={portrait.childImageSharp.fluid} />
      </MediaQuery>
    </BackgroundImage>
    <Overlay grid={grid}>{children}</Overlay>
  </Wrapper>
);

export default ImageSection;
